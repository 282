import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionService } from '@shared/services/transition-service.service';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { Viewer } from 'marzipano';
import { sharedHotspots, walkthrough, app } from '@shared/data/data.json';
import { StateService } from '@shared/services/state.service';
import { Viewpoint, Hotspot, Walkthrough } from '@models';

/**
 * Component for viewpoint page. Viewpoints may have existing and proposed screens, night (dark) screen, and Easter Egg screen.
 * "Proposed" mode may also have layers (overlay, vegetation, etc).
 */
@Component({
  selector: 'app-viewpoint',
  templateUrl: './viewpoint.component.html',
  styleUrls: ['./viewpoint.component.scss'],
})
export class ViewpointComponent implements OnInit {
  @ViewChild('tiles') tiles: ElementRef;
  app: any;
  viewer: Viewer = {};
  type: string;
  viewpoint: Viewpoint;
  sceneHotspots: any = [];
  viewpoints: Viewpoint[];
  sharedHotspots: any;
  walkthrough: Walkthrough;
  state: any;
  private existingScene: any;
  private proposedScene: any;
  private currentProposedScene: any; // this is used to keep track of layer scenes
  private darkScene: any;
  private easterEggScene: any;
  private homeViewpointSlug: string = 'site-interior';
  private refreshId: any;

  constructor(
    private route: ActivatedRoute,
    private viewpointsService: ViewpointsService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private router: Router
  ) {
    // force route reload whenever params change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.sharedHotspots = sharedHotspots;
    this.walkthrough = walkthrough;
    this.app = app;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // viewpoints other than "site-interior" (home screen) will have a param
      let viewpointSlug = params['name'];
      this.viewpoints = this.viewpointsService.getViewpoints();
      if (!viewpointSlug) {
        viewpointSlug = this.homeViewpointSlug;
      }
      this.viewpoint = this.viewpointsService.getViewpoint(viewpointSlug);
      this.type = this.viewpoint.parent ? 'existing' : 'proposed';
      this.state = this.stateService.getState();
      if (!this.state.viewpoints[this.viewpoint.slug]) {
        this.state.viewpoints[this.viewpoint.slug] = {};
        this.stateService.setState(this.state);
      }
    });
  }

  ngAfterViewInit(): void {
    this.initPanos();
  }

  ngOnDestroy(): void {
    // this.viewer.destroy();
    this.stop();
  }

  /**
   * Create the hotspots needed for a scene.
   * @param scene
   * @param viewpoint
   * @param type
   * @param altId
   */
  createSceneHotspots(scene, viewpoint, type, altId = '') {
    let hotspots: Hotspot[] = viewpoint.mode[type].hotspots;
    if (viewpoint.mode[type].sharedHotspotsSlug) {
      hotspots = this.sharedHotspots[viewpoint.mode[type].sharedHotspotsSlug];
    }
    for (let i = 0; i < hotspots.length; i++) {
      this.sceneHotspots.push({
        scene: scene,
        sceneId: viewpoint.slug + '-' + altId,
        hotspot: hotspots[i],
      });
    }
  }

  /**
   * Set up the Marzipano panos and hotspots needed for the current viewpoint.
   */
  initPanos() {
    const panoElement = this.tiles.nativeElement;
    this.viewer = new Viewer(panoElement, {
      stage: { progressive: true },
    });
    if (this.viewpoint.mode['proposed']) {
      this.proposedScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode['proposed'].slug
      );
      this.createSceneHotspots(this.proposedScene, this.viewpoint, 'proposed');
      if (this.viewpoint.mode['proposed'].darkSceneSlug) {
        this.darkScene = this.createScene(
          this.viewpoint,
          this.viewpoint.mode['proposed'].darkSceneSlug
        );
        this.createSceneHotspots(
          this.darkScene,
          this.viewpoint,
          'proposed',
          'dark'
        );
      }
      if (this.viewpoint.mode['proposed'].easterEggSceneSlug) {
        this.easterEggScene = this.createScene(
          this.viewpoint,
          this.viewpoint.mode['proposed'].easterEggSceneSlug
        );
      }
    }
    if (this.viewpoint.mode['existing']) {
      this.existingScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode['existing'].slug
      );
    }
    if (this.existingScene && this.state.global.mode === 'existing') {
      this.transitionService.currentScene = this.existingScene;
    } else {
      this.transitionService.currentScene = this.proposedScene;
    }
    this.currentProposedScene = this.proposedScene;
    this.transitionService.currentScene.switchTo();
    this.start();
  }

  /**
   * Create a scene for the current viewpoint.
   * @param viewpoint
   * @param sceneSlug
   * @returns
   */
  private createScene(viewpoint, sceneSlug) {
    let scene = this.transitionService.createMarzipanoScene(
      viewpoint,
      this.viewer,
      `../../assets/tiles/${sceneSlug}/{z}/{f}/{y}/{x}.jpg`
    );
    return scene;
  }

  /**
   * Toggle the scene "mode" between "existing" and "proposed".
   * @param args
   */
  public toggleScene = (args): void => {
    let mode = 'existing';
    if (args.checked) {
      mode = 'proposed';

      /***DON'T REMOVE - this was commented out because a minor bug with global toggle state */
      // let layerToggleState = this.stateService.getViewpointState(
      //   this.viewpoint.slug,
      //   'toggleState'
      // );
      // if (layerToggleState) {
      //   this.currentProposedScene = this.existingScene;
      //   this.state.global.mode = mode;
      //   this.stateService.setState(this.state);
      //   return this.toggleLayerScene(layerToggleState);
      // }
      /***END DON'T REMOVE */
    }
    this.state.global.mode = mode;
    this.stateService.setState(this.state);
    this.transitionService
      .nextScene(this.existingScene, this.currentProposedScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the layer scene. Uses data in the layers.map data structure in "shared/data/data.json".
   * @param args
   */
  public toggleLayerScene = (args: any): void => {
    let layerScene = null;
    let overlayChecked = args.overlay.checked ? 'on' : 'off';
    let vegetationChecked =
      args.vegetation && args.vegetation.checked ? 'on' : 'off';

    if (args.vegetation && vegetationChecked === 'off') {
      overlayChecked = 'off';
    }
    let slug = null;
    try {
      slug =
        this.viewpoint.mode['proposed'].layers.map.overlay[overlayChecked]
          .vegetation[vegetationChecked].slug;
    } catch (error) {
      throw new Error('No slug exists in data.json for this layer');
    }
    this.state.toggleState = args;
    this.state = this.stateService.setViewpointState(
      this.viewpoint.slug,
      'toggleState',
      args
    );
    layerScene = this.createScene(this.viewpoint, slug);
    this.transitionService
      .nextScene(this.currentProposedScene, layerScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
    this.transitionService.currentScene = layerScene;
    this.currentProposedScene = layerScene;
    // let layerScene = null;
    // let overlayChecked = args.overlay.checked ? 'on' : 'off';
    // let vegetationChecked = args.vegetation.checked ? 'on' : 'off';

    // let slug =
    //   this.viewpoint.mode['proposed'].layers.map.overlay[overlayChecked]
    //     .vegetation[vegetationChecked].slug;
    // this.state.toggleState = args;
    // this.state = this.stateService.setViewpointState(
    //   this.viewpoint.slug,
    //   'toggleState',
    //   args
    // );
    // layerScene = this.createScene(this.viewpoint, slug);
    // this.transitionService
    //   .nextScene(this.currentProposedScene, layerScene)
    //   .switchTo({
    //     transitionDuration: this.transitionService.TRANSITION_DURATION,
    //   });
    // this.transitionService.currentScene = layerScene;
    // this.currentProposedScene = layerScene;
  };

  /**
   * Toggle the Dark scene.
   * @param $event
   */
  public toggleDarkScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.darkScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the Easter Egg scene.
   * @param $event
   */
  public toggleEasterEggScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.easterEggScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Starts the polling using in the compass and map viewcone rotation.
   */
  start() {
    let that = this;
    this.refreshId = setInterval(function () {
      let viewParams = that.viewer.view().parameters();
      that.viewpoint.yaw = viewParams.yaw;
      that.viewpointsService.onUpdateViewpoint.emit(that.viewpoint);
    }, 100);
  }

  /**
   * Clear the polling using in the compass and map viewcone rotation.
   */
  stop() {
    clearInterval(this.refreshId);
  }
}
