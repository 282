<header>
  <div class="main">
    <div class="container inner">
      <img width="71" height="57" src="../../../../assets/logos/logo.png" alt="Logo" />
      <app-nav></app-nav>
      <app-social-links></app-social-links>
    </div>
    
  </div>
  <div class="container">
    <div class="header-secondary" [ngClass]="viewpoint?.parent ? 'has-back-btn' : ''">
      <div class="tab" routerLink="/" >
        <h3>Open House</h3>
      </div>
      <span *ngIf="viewpoint && viewpoint.parent" class="item numberplate">
        <span [innerHTML]="viewpoint.roadsign.title"></span>
        <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
      </span>
    </div>
    
  </div>
</header>